import React from 'react'
import {Outlet} from 'react-router-dom'
import Header from './components/Header'
const App = () => {
  return (
    <>
      <Header/>
      <Outlet/>

    </>
  )
}
 
export default App
